import { AppEnvironment, FirebaseEnvironment, StoreURL } from './entities/app_envirinment'

const firebase: FirebaseEnvironment = {
    apiKey: 'AIzaSyA0kaDZQfSzhzkNGrg23htxwV0Wiy-SA8o',
    authDomain: 'mc-app-kajitsuyacafe-dev.firebaseapp.com',
    projectId: 'mc-app-kajitsuyacafe-dev',
    storageBucket: 'mc-app-kajitsuyacafe-dev.appspot.com',
    messagingSenderId: '877532225555',
    appId: '1:877532225555:web:29b7f000ad0ca75fcc1fe6',
    measurementId: 'G-NS5TT3NN88',
}

const storeURL: StoreURL = {
    ios: 'https://apps.apple.com/app/6478639040',
    android: 'https://play.google.com/store/apps/details?id=jp.kajitsuyacafe.members',
}

export const environment: AppEnvironment = {
    production: false,
    brandCode: 'kajitsuya',
    env: 'dev',
    firebase,
    hostingBaseURL: 'https://app-dev.kajitsuyacafe.jp',
    appScheme: 'kajitsuyacafe-monogatari-app-dev',
    storeURL,
}
